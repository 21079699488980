import React from 'react';
import styled from 'styled-components';
import { Layout, Centralized } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { HeaderText } from '../components/shared/HeaderText';
import { Text } from '../components/shared/Text';
import { Button } from '../components/shared/Button';
import FinalisedSuccessImageSource from '../images/finalise-success-500.png';

const StyledDeliveryConfirmed = styled(Layout)`
`;

const CongratsImage = styled.img.attrs({
  src: FinalisedSuccessImageSource,
})`
  display: block;
  margin: 3rem auto 2rem;
  width: 14rem;
`;

const DeliveryConfirmed = () => (
  <StyledDeliveryConfirmed>
    <Page>
      <Centralized>
        <HeaderText>
          Congratulations!
        </HeaderText>
        <Text>
          Thank you for confirming your delivery.
        </Text>
        <CongratsImage />
        <Button>Track my parcel</Button>
      </Centralized>
    </Page>
  </StyledDeliveryConfirmed>
);

export default DeliveryConfirmed;