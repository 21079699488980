import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES } from '../../../style'

const StyledText = styled.div`
  font-family: ${STYLES.FONT_FAMILY};
  font-size: 1rem;
`;

const Text = ({ children, className }) => (
  <StyledText className={className}>
    { children }
  </StyledText>
);

Text.defaultProps = {
  className: null,
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export { Text };